<template>
  <div class="ebright-editor" :class="[addClass]">
    <editor-content v-if="loaded" class="editor__content ebright-post-content" :editor="editor" />
    <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${(menu.left + 20)}px; bottom: ${(menu.bottom - 65)}px;`"
      >
        <v-btn x-small text @click="commands.bold">
          <v-icon :class="[isActive.bold() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
            mdi-format-bold
          </v-icon>
        </v-btn>
        <v-btn x-small text @click="commands.italic">
          <v-icon :class="[isActive.italic() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
            mdi-format-italic
          </v-icon>
        </v-btn>
        <v-btn x-small text @click="commands.strike">
          <v-icon :class="[isActive.strike() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
            mdi-format-strikethrough
          </v-icon>
        </v-btn>
        <v-btn x-small text @click="commands.underline">
          <v-icon :class="[isActive.underline() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
            mdi-format-underline
          </v-icon>
        </v-btn>
        <v-btn x-small text @click="commands.code">
          <v-icon :class="[isActive.code() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
            mdi-code-tags
          </v-icon>
        </v-btn>
        <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="primary--text" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <v-btn x-small text @click="setLinkUrl(commands.link, null)">
            <v-icon close class="caption">
              mdi-close
            </v-icon>
          </v-btn>
        </form>
        <template v-else>
          <v-btn x-small text @click="showLinkMenu(getMarkAttrs('link'))">
            <v-icon :class="[isActive.link() ? 'white--text' : 'grey--text lighten-2']" class="body-1">
              mdi-link-plus
            </v-icon>
          </v-btn>
        </template>
      </div>
    </editor-menu-bubble>
    <editor-menu-bar v-if="loaded" :editor="editor" v-slot="{ commands, isActive }">
      <v-row no-gutters class="menubar">
        <v-col cols="9" class="col-md-10">
          <v-btn x-small :depressed="isActive.paragraph()" text @click="commands.paragraph">
            <v-icon class="caption">
              mdi-format-paragraph
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.heading({ level: 1 })" text @click="commands.heading({ level: 1 })">
            <v-icon class="caption">
              mdi-format-header-1
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.heading({ level: 2 })" text @click="commands.heading({ level: 2 })">
            <v-icon class="caption">
              mdi-format-header-2
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.heading({ level: 3 })" text @click="commands.heading({ level: 3 })">
            <v-icon class="caption">
              mdi-format-header-3
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.blockquote()" text @click="commands.blockquote">
            <v-icon class="caption">
              mdi-format-quote-close
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.code_block()" text @click="commands.code_block">
            <v-icon class="caption">
              mdi-code-tags
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.bullet_list()" text @click="commands.bullet_list">
            <v-icon class="caption">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.ordered_list()" text @click="commands.ordered_list">
            <v-icon class="caption">
              mdi-format-list-numbered
            </v-icon>
          </v-btn>
          <v-btn x-small :depressed="isActive.horizontal_rule()" text @click="commands.horizontal_rule">
            <v-icon class="caption">
              mdi-vector-line
            </v-icon>
          </v-btn>
          <v-btn x-small text @click="UploadImage(commands.image)">
            <v-icon class="caption">
              mdi-image
            </v-icon>
          </v-btn>
          <v-btn text x-small class="emoticon-btn" @click="toggleEmoticon = !toggleEmoticon">
            <v-icon class="caption">
              mdi-emoticon-outline
            </v-icon>
            <span v-if="toggleEmoticon" class="emoticon-btn-area" @click.stop>
              <template v-for="(emot, iEmot) in $store.state.emoticons.emoticons">
                <span v-if="emot[0].length > 5" :key="'emot-' + iEmot" @click.prevent="SetEmoticon(emot)">
                  {{ emot[1] }}
                </span>
              </template>
            </span>
          </v-btn>
        </v-col>
        <v-col cols="3" class="col-md-2 text-right">
          <v-btn x-small text @click="commands.undo">
            <v-icon class="caption">
              mdi-undo
            </v-icon>
          </v-btn>
          <v-btn x-small text @click="commands.redo">
            <v-icon class="caption">
              mdi-redo
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </editor-menu-bar>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image
} from 'tiptap-extensions'

const prepare = {
  extensions: [
    new Blockquote(),
    new BulletList(),
    new CodeBlock(),
    new HardBreak(),
    new Heading({ levels: [1, 2, 3] }),
    new HorizontalRule(),
    new ListItem(),
    new OrderedList(),
    new TodoItem(),
    new TodoList(),
    new Link(),
    new Bold(),
    new Code(),
    new Italic(),
    new Strike(),
    new Underline(),
    new History(),
    new Image()
  ],
  content: ''
}

export default {
  props: {
    addClass: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble
  },
  data () {
    return {
      loaded: false,
      editor: null,
      keepInBounds: true,
      linkUrl: '',
      linkMenuIsActive: false,
      toggleEmoticon: false
    }
  },
  watch: {
    content: function (v) {
      if (!v) {
        this.editor.clearContent()
      }
    }
  },
  mounted () {
    prepare.content = this.content
    this.editor = new Editor(prepare)
    // editor.on('init', () => {
    // })
    this.loaded = true
    this.editor.on('update', ({ getHTML }) => {
      this.$emit('save', this.$emoticonToString(getHTML(), this.$store.state.emoticons.emoticons))
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    SetEmoticon (emot) {
      const transaction = this.editor.state.tr.insertText(emot[1])
      this.editor.view.dispatch(transaction)
      this.toggleEmoticon = false
    },
    UploadImage (command) {
      const f = document.getElementById('imageUploaderPost')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploaderPost')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, command)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, command) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          // $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data
          }).then((resUpload) => {
            if (resUpload) {
              command({
                src: resUpload.data.data.secure_url
              })
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Upload failed' })
            }
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ebright-editor {
  padding: 15px 20px 15px 20px;
  background: #646464;
  &.comment-field {
    >.editor__content {
      >.ProseMirror {
        outline: none;
        min-height: 75px;
      }
    }
  }
  &.comment-field-sub {
    >.editor__content {
      >.ProseMirror {
        outline: none;
        min-height: 50px;
      }
    }
  }
  >.editor__content {
    padding-bottom: 15px;
    .ProseMirror {
      outline: none;
      min-height: 100px;
    }
  }
  .menubar {
    >.menubar__button {
      display: inline-block;
      padding: 2px 5px;
      cursor: pointer;
    }
  }
}
.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: .3rem;
  margin-bottom: .5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .2s,visibility .2s;
  transition: opacity .2s,visibility .2s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  input {
    outline: none;
  }
  >form {
    >button {
      position: absolute;
      right: 0px;
      top: 6px;
      i {
        color: #aaa!important;
      }
    }
  }
}
// .theme--dark {
//   .ebright-editor {
//     background: #252525;
//     .comment-field {
//     }
//   }
// }
.emoticon-btn {
  position: relative;
  .emoticon-btn-area {
    position: absolute;
    bottom: 28px;
    right: -5px;
    width: 170px;
    height: 100px;
    border-radius: 7px 7px 0px 7px;
    overflow-y: auto;
    padding: 5px 0px 10px 0px;
    background: #000;
    text-align: center;
    white-space: pre-wrap;
    >span {
      display: inline-block;
      font-size: 18px;
      margin: 1px;
    }
  }
}
</style>
